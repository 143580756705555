<template>
<div>
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
    <side-bar></side-bar>
    <!-- partial -->
    <div class="main-panel">
        <div class="content-wrapper">

        <div class="warning-message" v-if="response.subscription_warning">
            <p>{{response.subscription_warning}}</p>
        </div>

        <div class="page-header">
            <h3 class="page-title">
            <span class="page-title-icon bg-gradient-primary text-white me-2">
                <i class="mdi mdi-home"></i>
            </span> Dashboard
            </h3>
            <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                <span></span>Overview <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                </li>
            </ul>
            </nav>
        </div>

        <div class="row">
            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-danger card-img-holder text-white">
                    <div class="card-body">
                        <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                        <h4 class="mb-3"><i class="mdi mdi-book-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Today OPDs</h4>
                        <h2 class="mb-0">{{response.total_today_waiting_opd}}/{{response.total_today_opd}}</h2>
                        <!-- <h6 class="card-text">Increased by 60%</h6> -->
                    </div>
                </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-info card-img-holder text-white">
                    <div class="card-body">
                        <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                        <h4 class="mb-3"><i class="mdi mdi-book-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Today Admitted</h4>
                        <h2 class="mb-0">{{response.total_today_ipd}}</h2>
                    </div>
                </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                            <div class="card bg-gradient-success card-img-holder text-white">
                                <div class="card-body">
                                    <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                                    <h4 class="mb-3"><i class="mdi mdi-book-plus mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Today Appointments</h4>
                                    <h2 class="mb-0" >{{response.today_appointments}}</h2>
                                    <!-- <h6 class="card-text">Decreased by 10%</h6> -->
                     </div>
                 </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                            <div class="card bg-gradient-danger card-img-holder text-white">
                                <div class="card-body">
                                    <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                                    <h4 class="mb-3"><i class="mdi mdi-account-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Today New Patient
                                    </h4>
                                    <h2 class="mb-0">{{response.today_new_patient}}</h2>
                                </div>
                            </div>
                        </div>


            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-info card-img-holder text-white">
                    <div class="card-body">
                      <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                      <h4 class="mb-3"><i class="mdi mdi-book-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Total OPDs</h4>
                      <h2 class="mb-0" v-if="response.total_opd">{{response.total_opd}}</h2>
                      <h2 class="mb-0" v-else>-</h2>
                </div>
              </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-success card-img-holder text-white">
                    <div class="card-body">
                      <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                      <h4 class="mb-3"><i class="mdi mdi-book-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Total IPDs</h4>
                      <h2 class="mb-0" v-if="response.total_ipd">{{response.total_ipd}}</h2>
                      <h2 class="mb-0" v-else>-</h2>
                </div>
              </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-danger card-img-holder text-white">
                    <div class="card-body">
                        <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                        <h4 class="mb-3"><i class="mdi mdi-account-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Total Patient</h4>
                        <h2 class="mb-0" v-if="response.total_patient">{{response.total_patient}}</h2>
                        <h2 class="mb-0" v-else>-</h2>
                    </div>
                </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-info card-img-holder text-white">
                    <div class="card-body">
                        <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                        <h4 class="mb-3"><i class="mdi mdi-book-plus mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Online Appointments</h4>
                        <h2 class="mb-0" v-if="response.total_appointments">{{response.total_appointments}}</h2>
                        <h2 class="mb-0" v-else>-</h2>
                        <!-- <h6 class="card-text">Increased by 5%</h6> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 grid-margin stretch-card" v-if="response.role_users || (response.total_departments != -1 || response.total_medicines != -1 || response.total_reports != -1 || response.total_cities != -1)">
            <div class="card">
                <div class="card-body">
                    <div class="row" v-if="response.role_users">
                        <div class="col-md-3" v-for="(role,i) in response.role_users" :key="i">
                            <div class="line-box">
                                <h5 class="text-secondary font-weight-light text-uppercase ls-100">{{role.role__name}}</h5>
                                <h2 class="mb-0" v-if="role.user_count">{{role.user_count}}</h2>
                                <h2 class="mb-0" v-else>-</h2>
                            </div>
                        </div>
                    </div>
                    <br class="d-none d-md-block" v-if="response.role_users && (response.total_departments != -1 || response.total_medicines != -1 || response.total_reports != -1 || response.total_cities != -1)"/>
                    <hr class="d-none d-md-block" color="lightgrey" v-if="response.role_users && (response.total_departments != -1 || response.total_medicines != -1 || response.total_reports != -1 || response.total_cities != -1)"/>
                    <br class="d-none d-md-block" v-if="response.role_users && (response.total_departments != -1 || response.total_medicines != -1 || response.total_reports != -1 || response.total_cities != -1)"/>
                    <div class="row">
                        <div class="col-md-3" v-if="response.total_departments != -1">
                            <div class="line-box">
                                <h5 class="text-secondary font-weight-light text-uppercase ls-100">Total Departments</h5>
                                <h2 class="mb-0" v-if="response.total_departments">{{response.total_departments}}</h2>
                                <h2 class="mb-0" v-else>-</h2>
                            </div>
                        </div>
                        <div class="col-md-3" v-if="response.total_medicines != -1">
                            <div class="line-box">
                                <h5 class="text-secondary font-weight-light text-uppercase ls-100">Total Medicine</h5>
                                <h2 class="mb-0" v-if="response.total_medicines">{{response.total_medicines}}</h2>
                                <h2 class="mb-0" v-else>-</h2>
                            </div>
                        </div>
                        <div class="col-md-3" v-if="response.total_reports != -1">
                            <div class="line-box">
                                <h5 class="text-secondary font-weight-light text-uppercase ls-100">Total Reports</h5>
                                <h2 class="mb-0" v-if="response.total_reports">{{response.total_reports}}</h2>
                                <h2 class="mb-0" v-else>-</h2>
                            </div>
                        </div>
                        <div class="col-md-3"v-if="response.total_cities != -1">
                            <div class="line-box">
                                <h5 class="text-secondary font-weight-light text-uppercase ls-100">Total Cities</h5>
                                <h2 class="mb-0" v-if="response.total_cities">{{response.total_cities}}</h2>
                                <h2 class="mb-0" v-else>-</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 grid-margin stretch-card"  v-show="statistics_data && statistics_data.length">
                <div class="card">
                    <div class="card-body">
                        <div class="chartjs-size-monitor">
                            <div class="chartjs-size-monitor-expand">
                                <div class=""></div>
                            </div>
                            <div class="chartjs-size-monitor-shrink">
                                <div class=""></div>
                            </div>
                        </div>
                        <div class="clearfix">
                            <div class="row">
                            <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">Statistics</h4>
                                <div class="col-lg-6">
                                <multiselect v-model="statistics_selected_options" :options="statistics_options" :multiple="false"
                                                            :close-on-select="true" label="display_name"
                                                            :clear-on-select="false" :preserve-search="true" placeholder="Please select range"
                                                             :preselect-first="true" :showLabels="false" @input=getDashboard()></multiselect>

                                </div>
                                </div>
                            <div id="visit-sale-chart-legend" class="rounded-legend legend-horizontal legend-top-right justify-content-center">
                                <ul class="ps-0 mt-3 mb-0">
                                    <li><span class="legend-dots" style="background:linear-gradient(to right, rgba(218, 140, 255, 1), rgba(154, 85, 255, 1))"></span>OPDs</li>
                                    <li><span class="legend-dots" style="background:linear-gradient(to right, rgba(0, 63, 92, 1), rgba(177, 148, 250, 1))"></span>IPDs</li>
                                    <li><span class="legend-dots" style="background:linear-gradient(to right, rgba(255, 191, 150, 1), rgba(254, 112, 150, 1))"></span>Patients</li>
                                    <li><span class="legend-dots" style="background:linear-gradient(to right, rgba(54, 215, 232, 1), rgba(177, 148, 250, 1))"></span>Appointments</li></ul></div>
                            </div>

                        <canvas id="visit-sale-chart" class="mt-4 chartjs-render-monitor" width="497" height="248" style="display: block; width: 497px; height: 248px;"></canvas>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 grid-margin stretch-card"  v-show="department_statistics_data && department_statistics_data.length">
                <div class="card">
                    <div class="card-body">
                        <div class="chartjs-size-monitor">
                            <div class="chartjs-size-monitor-expand">
                                <div class=""></div>
                            </div>
                            <div class="chartjs-size-monitor-shrink">
                                <div class=""></div>
                            </div>
                        </div>
                        <div class="clearfix">
                            <div class="row">
                            <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">Department Statistics</h4>
                                <div class="col-lg-6">
                                <multiselect v-model="department_statistics_selected_options" :options="statistics_options" :multiple="false"
                                                            :close-on-select="true" label="display_name"
                                                            :clear-on-select="false" :preserve-search="true" placeholder="Please select range"
                                                             :preselect-first="true" :showLabels="false" @input=getDashboard()></multiselect>

                                </div>
                                </div>
                            <div id="department-chart-legend" class="rounded-legend legend-horizontal legend-top-right justify-content-center"><ul class="ps-0 mt-3 mb-0"><li><span class="legend-dots" style="background:linear-gradient(to right, rgba(218, 140, 255, 1), rgba(154, 85, 255, 1))"></span>OPDs</li><li><span class="legend-dots" style="background:linear-gradient(to right, rgba(54, 215, 232, 1), rgba(177, 148, 250, 1))"></span>Appointments</li></ul></div>
                            </div>

                        <canvas id="department-chart" class="mt-4 chartjs-render-monitor" width="497" height="248" style="display: block; width: 497px; height: 248px;"></canvas>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 grid-margin stretch-card" v-show="doctor_statistics_data && doctor_statistics_data.length">
                <div class="card">
                    <div class="card-body">
                        <div class="chartjs-size-monitor">
                            <div class="chartjs-size-monitor-expand">
                                <div class=""></div>
                            </div>
                            <div class="chartjs-size-monitor-shrink">
                                <div class=""></div>
                            </div>
                        </div>
                        <div class="clearfix">
                            <div class="row">
                                <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">Doctor vs OPDs</h4>
                                <div class="col-lg-6">
                                    <multiselect v-model="doctor_statistics_selected_options" :options="statistics_options" :multiple="false"
                                                            :close-on-select="true" label="display_name"
                                                            :clear-on-select="false" :preserve-search="true" placeholder="Please select range"
                                                             :preselect-first="true" :showLabels="false" @input=getDashboard()></multiselect>

                                </div>
                            </div>
                        </div>
                        <canvas id="doctor-chart" style="height:250px" class="mt-2"></canvas>
                        <br/>
                        <small class="text-danger"><b>Inactive doctors not included but OPD is counted.</b></small>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 grid-margin stretch-card" v-show="earning_statistics_data && earning_statistics_data.length">
                <div class="card">
                    <div class="card-body">
                        <div class="chartjs-size-monitor">
                            <div class="chartjs-size-monitor-expand">
                                <div class=""></div>
                            </div>
                            <div class="chartjs-size-monitor-shrink">
                                <div class=""></div>
                            </div>
                        </div>
                        <div class="clearfix">
                            <div class="row">
                            <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">Earnings</h4>
                                <div class="col-lg-6">
                                <multiselect v-model="earnings_statistics_selected_options" :options="statistics_options" :multiple="false"
                                                            :close-on-select="true" label="display_name"
                                                            :clear-on-select="false" :preserve-search="true" placeholder="Please select range"
                                                             :preselect-first="true" :showLabels="false" @input=getDashboard()></multiselect>

                                </div>
                                </div>
                            <div id="visit-earning-chart-legend" class="rounded-legend legend-horizontal legend-top-right justify-content-center">
                                <ul class="ps-0 mt-3 mb-0">
                                    <li><span class="legend-dots" style="background:linear-gradient(to right, rgba(175, 82, 222, 1), rgba(123, 31, 162, 1))"></span>Total Earnings</li>
                                    <li><span class="legend-dots" style="background:linear-gradient(to right, rgba(103, 58, 183, 1), rgba(63, 81, 181, 1))"></span>OPDs</li>
                                    <li><span class="legend-dots" style="background:linear-gradient(to right, rgba(0, 102, 204, 1), rgba(41, 128, 185, 1))"></span>IPDs</li>
                                    <li><span class="legend-dots" style="background:linear-gradient(to right, rgba(255, 87, 51, 1), rgba(231, 76, 60, 1))"></span>Reports</li>
                                    <li><span class="legend-dots" style="background:linear-gradient(to right, rgba(26, 188, 156, 1), rgba(22, 160, 133, 1))"></span>Medicines</li>
                                </ul>
                            </div>
                            <canvas id="visit-earning-chart" class="mt-4 chartjs-render-monitor" width="497" height="248" style="display: block; width: 497px; height: 248px;"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <!-- content-wrapper ends -->
    <!-- partial:partials/_footer.html -->
    <Footer/>
        <!-- partial -->
    </div>
    <!-- main-panel ends -->
    </div>
    </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions} from "vuex";
import * as Sc from "../assets/vendors/chart.js/Chart.min.js"
import Multiselect from 'vue-multiselect';
import string from "../constant/strings.js"

export default {
    name:'Front',
    title: string.PAGE_TITLE_DASHBOARD,
    components: {
        NavBar,
        SideBar,
        Multiselect,
        Footer,
    },
    data() {
    const now = new Date()
    const current_year = now.getFullYear() - 5

    return {
      statistics_data:[],
      department_statistics_data:[],
      earning_statistics_data:[],
      doctor_statistics_data:[],
      statistics_options:[{"display_name":"Last 3 month","api_value":3}, {"display_name":"Last 6 month","api_value":6}, {"display_name":"Last 12 month","api_value":12}, {"display_name":"Last 2 Year","api_value":24},  {"display_name":"Last 3 Year","api_value":36},  {"display_name":"Last 4 Year","api_value":48},  {"display_name":"Last 5 Year","api_value":60}],
      statistics_selected_options:{"display_name":"Last 3 month","api_value":3},
      department_statistics_selected_options:{"display_name":"Last 3 month","api_value":3},
      earnings_statistics_selected_options:{"display_name":"Last 3 month","api_value":3},
      doctor_statistics_selected_options:{"display_name":"Last 3 month","api_value":3},
      current_year:current_year,
      myChart:null,
      response:{},
    }
    },
    mounted() {
        this.getDashboard();
        this.updateFilters();
    },
    methods:{
        ...mapActions("hospital",["getDashboardData"]),
        getDashboard(){
        console.log(this.statistics_selected_options.api_value)
         var bodyFormData = new FormData();
         bodyFormData.append('dashboard_filter', this.statistics_selected_options.api_value)
         bodyFormData.append('dashboard_department_filter', this.department_statistics_selected_options.api_value)
         bodyFormData.append('dashboard_earning_filter', this.earnings_statistics_selected_options.api_value)
         bodyFormData.append('dashboard_doctor_filter', this.doctor_statistics_selected_options.api_value)
          this.getDashboardData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
              this.response = response,
              this.statistics_data=response.statistics_data
              this.department_statistics_data=response.department_statistics_data
              this.earning_statistics_data=response.earning_statistics_data
              this.doctor_statistics_data=response.doctor_statistics_data
              this.setChartDetails()
              this.setDepartmentChartDetails()

              if (this.earning_statistics_data){
                this.setEarningsChartDetails()
              }

              this.setDoctorChartDetails()
            } else {
              this.$toasted.error(response.message, {duration: 2000,});
            }
          });
        },
        setChartDetails() {

        let statics_label = []
        let statics_data_opd = []
        let statics_data_ipd = []
        let statics_data_patients = []
        let statics_data_appointments = []
        this.statistics_data.forEach((statistics_obj) => {
              const results = Object.keys(statistics_obj)
              statics_label.push(results)
              statics_data_opd.push(Object.values(statistics_obj)[0].total_opd)
              statics_data_ipd.push(Object.values(statistics_obj)[0].total_ipd)
              statics_data_patients.push(Object.values(statistics_obj)[0].total_patient)
              statics_data_appointments.push(Object.values(statistics_obj)[0].total_appointments)
        })
        console.log(statics_data_opd)

        Chart.defaults.global.legend.labels.usePointStyle = true;
              var ctx = document.getElementById('visit-sale-chart').getContext("2d");

              var gradientStrokeViolet = ctx.createLinearGradient(0, 0, 0, 181);
              gradientStrokeViolet.addColorStop(0, 'rgba(218, 140, 255, 1)');
              gradientStrokeViolet.addColorStop(1, 'rgba(154, 85, 255, 1)');
              var gradientLegendViolet = 'linear-gradient(to right, rgba(218, 140, 255, 1), rgba(154, 85, 255, 1))';

              var gradientStrokeBlue = ctx.createLinearGradient(0, 0, 0, 360);
              gradientStrokeBlue.addColorStop(0, 'rgba(54, 215, 232, 1)');
              gradientStrokeBlue.addColorStop(1, 'rgba(177, 148, 250, 1)');
              var gradientLegendBlue = 'linear-gradient(to right, rgba(54, 215, 232, 1), rgba(177, 148, 250, 1))';

              var gradientStrokeRed = ctx.createLinearGradient(0, 0, 0, 300);
              gradientStrokeRed.addColorStop(0, 'rgba(255, 191, 150, 1)');
              gradientStrokeRed.addColorStop(1, 'rgba(254, 112, 150, 1)');
              var gradientLegendRed = 'linear-gradient(to right, rgba(255, 191, 150, 1), rgba(254, 112, 150, 1))';

              var gradientStrokeGreen = ctx.createLinearGradient(0, 0, 0, 300);
              gradientStrokeGreen.addColorStop(0, 'rgba(0, 63, 92, 1)');
              gradientStrokeGreen.addColorStop(1, 'rgba(177, 148, 250, 1)');
              var gradientLegendGreen = 'linear-gradient(to right, rgba(0, 63, 92, 1), rgba(177, 148, 250, 1))';

              if(ctx.myChart != null){
                ctx.myChart.destroy()
              }

              this.myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: statics_label,
                    datasets: [
                      {
                        label: "OPDs",
                        borderColor: gradientStrokeViolet,
                        backgroundColor: gradientStrokeViolet,
                        hoverBackgroundColor: gradientStrokeViolet,
                        legendColor: gradientLegendViolet,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: statics_data_opd
                      },
                      {
                        label: "IPDs",
                        borderColor: gradientStrokeGreen,
                        backgroundColor: gradientStrokeGreen,
                        hoverBackgroundColor: gradientStrokeGreen,
                        legendColor: gradientLegendGreen,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: statics_data_ipd
                      },
                      {
                        label: "Patients",
                        borderColor: gradientStrokeRed,
                        backgroundColor: gradientStrokeRed,
                        hoverBackgroundColor: gradientStrokeRed,
                        legendColor: gradientLegendRed,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: statics_data_patients
                      },
                      {
                        label: "Appointments",
                        borderColor: gradientStrokeBlue,
                        backgroundColor: gradientStrokeBlue,
                        hoverBackgroundColor: gradientStrokeBlue,
                        legendColor: gradientLegendBlue,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: statics_data_appointments
                      }
                  ]
                },
                options: {
                  responsive: true,
                  legend: false,
                  legendCallback: function(chart) {
                    var text = [];
                    text.push('<ul>');
                    for (var i = 0; i < chart.data.datasets.length; i++) {
                        text.push('<li><span class="legend-dots" style="background:' +
                                   chart.data.datasets[i].legendColor +
                                   '"></span>');
                        if (chart.data.datasets[i].label) {
                            text.push(chart.data.datasets[i].label);
                        }
                        text.push('</li>');
                    }
                    text.push('</ul>');
                    return text.join('');
                  },
                  scales: {
                      yAxes: [{
                          ticks: {
                              display: false,
                              min: 0,
                              stepSize: 20,
                              max: 80
                          },
                          gridLines: {
                            drawBorder: false,
                            color: 'rgba(235,237,242,1)',
                            zeroLineColor: 'rgba(235,237,242,1)'
                          }
                      }],
                      xAxes: [{
                          gridLines: {
                            display:false,
                            drawBorder: false,
                            color: 'rgba(0,0,0,1)',
                            zeroLineColor: 'rgba(235,237,242,1)'
                          },
                          ticks: {
                              padding: 20,
                              fontColor: "#9c9fa6",
                              autoSkip: true,
                          },
                          categoryPercentage: 0.5,
                          barPercentage: 0.5
                      }]
                    }
                  },
                  elements: {
                    point: {
                      radius: 0
                    }
                  }
              })
              document.getElementById("visit-sale-chart-legend").html = (this.myChart.generateLegend());
        },
        setDepartmentChartDetails() {
        console.log("sdadadstatics_data_opd")

        let department_statics_label = []
        let department_statics_data_opd = []
        let department_statics_data_appointments = []
        this.department_statistics_data.forEach((department_statistics_obj) => {
              const results = Object.keys(department_statistics_obj)
              department_statics_label.push(results)
              department_statics_data_opd.push(Object.values(department_statistics_obj)[0].total_opd)
              department_statics_data_appointments.push(Object.values(department_statistics_obj)[0].total_appointments)
        })
        console.log("sdadadstatics_data_opd")
        console.log(department_statics_data_opd)

        Chart.defaults.global.legend.labels.usePointStyle = true;
              var ctx = document.getElementById('department-chart').getContext("2d");

              var gradientStrokeViolet = ctx.createLinearGradient(0, 0, 0, 181);
              gradientStrokeViolet.addColorStop(0, 'rgba(218, 140, 255, 1)');
              gradientStrokeViolet.addColorStop(1, 'rgba(154, 85, 255, 1)');
              var gradientLegendViolet = 'linear-gradient(to right, rgba(218, 140, 255, 1), rgba(154, 85, 255, 1))';

              var gradientStrokeBlue = ctx.createLinearGradient(0, 0, 0, 360);
              gradientStrokeBlue.addColorStop(0, 'rgba(54, 215, 232, 1)');
              gradientStrokeBlue.addColorStop(1, 'rgba(177, 148, 250, 1)');
              var gradientLegendBlue = 'linear-gradient(to right, rgba(54, 215, 232, 1), rgba(177, 148, 250, 1))';

              if(ctx.myChart != null){
                ctx.myChart.destroy()
              }

              this.myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: department_statics_label,
                    datasets: [
                      {
                        label: "OPDs",
                        borderColor: gradientStrokeViolet,
                        backgroundColor: gradientStrokeViolet,
                        hoverBackgroundColor: gradientStrokeViolet,
                        legendColor: gradientLegendViolet,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: department_statics_data_opd
                      },
                      {
                        label: "Appointments",
                        borderColor: gradientStrokeBlue,
                        backgroundColor: gradientStrokeBlue,
                        hoverBackgroundColor: gradientStrokeBlue,
                        legendColor: gradientLegendBlue,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: department_statics_data_appointments
                      }
                  ]
                },
                options: {
                  responsive: true,
                  legend: false,
                  legendCallback: function(chart) {
                    var text = [];
                    text.push('<ul>');
                    for (var i = 0; i < chart.data.datasets.length; i++) {
                        text.push('<li><span class="legend-dots" style="background:' +
                                   chart.data.datasets[i].legendColor +
                                   '"></span>');
                        if (chart.data.datasets[i].label) {
                            text.push(chart.data.datasets[i].label);
                        }
                        text.push('</li>');
                    }
                    text.push('</ul>');
                    return text.join('');
                  },
                  scales: {
                      yAxes: [{
                          ticks: {
                              display: false,
                              min: 0,
                              stepSize: 20,
                              max: 80
                          },
                          gridLines: {
                            drawBorder: false,
                            color: 'rgba(235,237,242,1)',
                            zeroLineColor: 'rgba(235,237,242,1)'
                          }
                      }],
                      xAxes: [{
                          gridLines: {
                            display:false,
                            drawBorder: false,
                            color: 'rgba(0,0,0,1)',
                            zeroLineColor: 'rgba(235,237,242,1)'
                          },
                          ticks: {
                              padding: 20,
                              fontColor: "#9c9fa6",
                              autoSkip: true,
                          },
                          categoryPercentage: 0.5,
                          barPercentage: 0.5
                      }]
                    }
                  },
                  elements: {
                    point: {
                      radius: 0
                    }
                  }
              })
              document.getElementById("department-chart-legend").html = (this.myChart.generateLegend());
        },
        setDoctorChartDetails() {
            let doctor_statics_label = []
            let doctor_statics_data = []

            this.doctor_statistics_data.forEach((doctor_statistics_obj) => {
                const results = Object.keys(doctor_statistics_obj)
                doctor_statics_label.push(results[0])
                doctor_statics_data.push(Object.values(doctor_statistics_obj)[0].opd_per)
            })

            var doctorChart = document.getElementById('doctor-chart');
            console.log(doctorChart);
            var ctx = doctorChart.getContext("2d");

            if(ctx.doctorChart != null){
                ctx.doctorChart.destroy()
            }

            var doctorChartData = {
                datasets: [{
                    data: doctor_statics_data,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(255, 206, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)',
                        'rgba(153, 102, 255, 0.5)',
                        'rgba(255, 159, 64, 0.5)'
                    ],
                    borderColor: [
                        'rgba(255,99,132,1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                }],
                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: doctor_statics_label
              };

              var doctorChartOptions = {
                responsive: true,
                animation: {
                  animateScale: true,
                  animateRotate: true
                }
              };

            var doctorChart = new Chart(ctx, {
                type: 'pie',
                data: doctorChartData,
                options: doctorChartOptions
            });
        },
        updateFilters() {
            while( this.current_year >= 2023 ) {
              this.statistics_options.push({"display_name":this.current_year,"api_value":this.current_year})
              this.current_year = this.current_year - 1
            }
        },
        setEarningsChartDetails() {
            let statics_label = []

            let statics_data_total = []
            let statics_data_opd = []
            let statics_data_ipd = []
            let statics_data_medicine = []
            let statics_data_reports = []

            this.earning_statistics_data.forEach((statistics_obj) => {
                  const results = Object.keys(statistics_obj)
                  statics_label.push(results)
                  statics_data_total.push(Object.values(statistics_obj)[0].total_earning)
                  statics_data_opd.push(Object.values(statistics_obj)[0].opd_earning)
                  statics_data_ipd.push(Object.values(statistics_obj)[0].ipd_earning)
                  statics_data_medicine.push(Object.values(statistics_obj)[0].medicine_earning)
                  statics_data_reports.push(Object.values(statistics_obj)[0].reports_earning)
            })

            Chart.defaults.global.legend.labels.usePointStyle = true;
                  var ctx = document.getElementById('visit-earning-chart').getContext("2d");

                var gradientStrokeTotalEarnings = ctx.createLinearGradient(0, 0, 0, 181);
                gradientStrokeTotalEarnings.addColorStop(0, 'rgba(175, 82, 222, 1)');
                gradientStrokeTotalEarnings.addColorStop(1, 'rgba(123, 31, 162, 1)');
                var gradientLegendTotalEarnings = 'linear-gradient(to right, rgba(175, 82, 222, 1), rgba(123, 31, 162, 1))';

                var gradientStrokeOPD = ctx.createLinearGradient(0, 0, 0, 181);
                gradientStrokeOPD.addColorStop(0, 'rgba(103, 58, 183, 1)');
                gradientStrokeOPD.addColorStop(1, 'rgba(63, 81, 181, 1)');
                var gradientLegendOPD = 'linear-gradient(to right, rgba(103, 58, 183, 1), rgba(63, 81, 181, 1))';

                var gradientStrokeIPD = ctx.createLinearGradient(0, 0, 0, 300);
                gradientStrokeIPD.addColorStop(0, 'rgba(0, 102, 204, 1)');
                gradientStrokeIPD.addColorStop(1, 'rgba(41, 128, 185, 1)');
                var gradientLegendIPD = 'linear-gradient(to right, rgba(0, 102, 204, 1), rgba(41, 128, 185, 1))';

                var gradientStrokeReports = ctx.createLinearGradient(0, 0, 0, 300);
                gradientStrokeReports.addColorStop(0, 'rgba(255, 87, 51, 1)');
                gradientStrokeReports.addColorStop(1, 'rgba(231, 76, 60, 1)');
                var gradientLegendReports = 'linear-gradient(to right, rgba(255, 87, 51, 1), rgba(231, 76, 60, 1))';

                var gradientStrokeMedicine = ctx.createLinearGradient(0, 0, 0, 360);
                gradientStrokeMedicine.addColorStop(0, 'rgba(26, 188, 156, 1)');
                gradientStrokeMedicine.addColorStop(1, 'rgba(22, 160, 133, 1)');
                var gradientLegendMedicine = 'linear-gradient(to right, rgba(26, 188, 156, 1), rgba(22, 160, 133, 1))';

                  if(ctx.myChart != null){
                    ctx.myChart.destroy()
                  }

                  this.myChart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: statics_label,
                        datasets: [
                          {
                            label: "Total Earnings",
                            borderColor: gradientStrokeTotalEarnings,
                            backgroundColor: gradientStrokeTotalEarnings,
                            hoverBackgroundColor: gradientStrokeTotalEarnings,
                            legendColor: gradientLegendTotalEarnings,
                            pointRadius: 0,
                            fill: false,
                            borderWidth: 1,
                            fill: 'origin',
                            data: statics_data_total
                          },
                          {
                            label: "OPDs",
                            borderColor: gradientStrokeOPD,
                            backgroundColor: gradientStrokeOPD,
                            hoverBackgroundColor: gradientStrokeOPD,
                            legendColor: gradientLegendOPD,
                            pointRadius: 0,
                            fill: false,
                            borderWidth: 1,
                            fill: 'origin',
                            data: statics_data_opd
                          },
                          {
                            label: "IPDs",
                            borderColor: gradientStrokeIPD,
                            backgroundColor: gradientStrokeIPD,
                            hoverBackgroundColor: gradientStrokeIPD,
                            legendColor: gradientLegendIPD,
                            pointRadius: 0,
                            fill: false,
                            borderWidth: 1,
                            fill: 'origin',
                            data: statics_data_ipd
                          },
                          {
                            label: "Reports",
                            borderColor: gradientStrokeReports,
                            backgroundColor: gradientStrokeReports,
                            hoverBackgroundColor: gradientStrokeReports,
                            legendColor: gradientLegendReports,
                            pointRadius: 0,
                            fill: false,
                            borderWidth: 1,
                            fill: 'origin',
                            data: statics_data_reports
                          },
                          {
                            label: "Medicines",
                            borderColor: gradientStrokeMedicine,
                            backgroundColor: gradientStrokeMedicine,
                            hoverBackgroundColor: gradientStrokeMedicine,
                            legendColor: gradientLegendMedicine,
                            pointRadius: 0,
                            fill: false,
                            borderWidth: 1,
                            fill: 'origin',
                            data: statics_data_medicine
                          },
                      ]
                    },
                    options: {
                      responsive: true,
                      legend: false,
                      legendCallback: function(chart) {
                        var text = [];
                        text.push('<ul>');
                        for (var i = 0; i < chart.data.datasets.length; i++) {
                            text.push('<li><span class="legend-dots" style="background:' +
                                       chart.data.datasets[i].legendColor +
                                       '"></span>');
                            if (chart.data.datasets[i].label) {
                                text.push(chart.data.datasets[i].label);
                            }
                            text.push('</li>');
                        }
                        text.push('</ul>');
                        return text.join('');
                      },
                      scales: {
                          yAxes: [{
                              ticks: {
                                  display: false,
                                  min: 0,
                                  stepSize: 20,
                                  max: 80
                              },
                              gridLines: {
                                drawBorder: false,
                                color: 'rgba(235,237,242,1)',
                                zeroLineColor: 'rgba(235,237,242,1)'
                              }
                          }],
                          xAxes: [{
                              gridLines: {
                                display:false,
                                drawBorder: false,
                                color: 'rgba(0,0,0,1)',
                                zeroLineColor: 'rgba(235,237,242,1)'
                              },
                              ticks: {
                                  padding: 20,
                                  fontColor: "#9c9fa6",
                                  autoSkip: true,
                              },
                              categoryPercentage: 0.5,
                              barPercentage: 0.5
                          }]
                        }
                      },
                      elements: {
                        point: {
                          radius: 0
                        }
                      }
                  })
                  document.getElementById("visit-earning-chart-legend").html = (this.myChart.generateLegend());
            },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>